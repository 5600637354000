<template>
	<DefaultLayout>
		<div
			id="wrap404"
			class="p-4"
		>
			<Error500
				v-if="show500"
				:status="status"
			/>
			<Error404 v-else />
		</div>
	</DefaultLayout>
</template>

<script async>
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		Error404: () => import('@/components/error/404.vue'),
		Error500: () => import('@/components/error/500.vue'),
		DefaultLayout
	},
	props: {
		status: {
			type: [ String, Number ],
			default: ''
		}
	},
	computed: {
		show500() { return this.status > 404 || this.$route.name === 'error' }
	}
}
</script>
